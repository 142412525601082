import React from 'react'

const PrivacyPage = () => {
    return (
        <div className="font-inter body-padding pt-10 pb-20">
            <h1 className="text-2xl sm:text-[32px]/[40px] font-inter-bold font-bold">Haul247’s Privacy Policy</h1>

            <div className="mt-6">
                <h6 className="font-bold font-inter-md text-lg text-[#242424]">Introduction</h6>
                <p className="mt-2 text-base text-[#777777]">
                    Our privacy policy will help you understand what information we collect at Haul247 Technology Inc., how Haul247 Technology Inc. uses it, and
                    what choices you have. Haul247 Technology Inc. built the Haul247 Internal App as a free app. This service is provided by Haul247 Technology
                    Inc. at no cost and is intended for use as is. If you choose to use our Service, then you agree to the collection and use of information in
                    relation to this policy. The personal data or information we collect is used to provide and improve the service. We will not use or share
                    your information with anyone except as described in this Privacy Policy. The terms used in this Privacy Policy have the same meanings as in
                    our Terms and Conditions, which are accessible on our website unless otherwise defined in this Privacy Policy.
                </p>
            </div>

            <div className="mt-6">
                <h6 className="font-bold font-inter-md text-lg text-[#242424]">Information Collection and Use</h6>
                <p className="mt-2 text-base text-[#777777] ">
                    For a better experience while using our Service, we may require you to provide us with certain personally identifiable information,
                    including but not limited to the user's name, email address, gender, location, and pictures. The information that we request will be
                    retained by us and used as described in this privacy policy. The app uses third-party services that may collect information used to identify
                    you.
                </p>
            </div>

            <div className="mt-6">
                <h6 className="font-bold font-inter-md text-lg text-[#242424]">Cookies</h6>
                <p className="mt-2 text-base text-[#777777] ">
                    Cookies are files with a small amount of data that are commonly used as an anonymous unique identifier. These are sent to your browser from
                    the website that you visit and are stored on your device’s internal memory.
                </p>
                <p className="mt-2 text-base text-[#777777] ">
                    This Service does not use these “cookies” explicitly. However, the app may use third- party code and libraries that use “cookies” to collect
                    information and to improve their services. You can either accept or refuse these cookies and know when a cookie is being sent to your
                    device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
                </p>
            </div>

            <div className="mt-6">
                <h6 className="font-bold font-inter-md text-lg text-[#242424]">Location Information</h6>
                <p className="mt-2 text-base text-[#777777] ">
                    Some of the services may use location information transmitted from users' mobile phones. We only use this information within the scope
                    necessary for the designated service.
                </p>
            </div>

            <div className="mt-6">
                <h6 className="font-bold font-inter-md text-lg text-[#242424]">Device Information</h6>
                <p className="mt-2 text-base text-[#777777] ">
                    We collect information from your device in some cases. The information will be utilized for the provision of better service and to prevent
                    fraudulent acts. Additionally, such information will not include that which will identify the individual user.
                </p>
            </div>

            <div className="mt-6">
                <p className="font-bold font-inter-md text-lg text-[#242424]">Service Providers</p>
                <p className="mt-2 text-base text-[#777777] ">We may employ third-party companies and individuals due to the following reasons:</p>
                <ul className="list-disc mt-2 my-5 ms-4 text-[#777777]">
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p className="text-base text-[#777777] ">
                    We want to inform users of this Service that these third parties have access to your Personal Information. The reason is to perform the
                    tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
                </p>
            </div>

            <div className="mt-6">
                <p className="font-bold font-inter-md text-lg text-[#242424]">Security</p>
                <p className="mt-2 text-base text-[#777777] ">
                    We value your trust in providing us with your Personal Information. Thus, we are striving to use commercially acceptable means of protecting
                    it. But remember that no method of transmission over the internet or method of electronic storage is 100% secure and reliable, and we cannot
                    guarantee its absolute security.
                </p>
            </div>

            <div className="mt-6">
                <p className="font-bold font-inter-md text-lg text-[#242424]">Children’s Privacy</p>
                <p className="mt-2 text-base text-[#777777] ">
                    This Service only addresses people under the age of 18. We do not knowingly collect personally identifiable information from children under
                    18. If we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are
                    a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able
                    to take the necessary actions.
                </p>
            </div>

            <div className="mt-6">
                <p className="font-bold font-inter-md text-lg text-[#242424]">Changes to This Privacy Policy</p>
                <p className="mt-2 text-base text-[#777777] ">
                    We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify
                    you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted.
                </p>
            </div>

            <div className="mt-6">
                <p className="font-bold font-inter-md text-lg text-[#242424]">Contact Us</p>
                <p className="mt-2 text-base text-[#777777] ">
                    If you have any questions or suggestions about our Privacy Policy, please contact us. <br /> Contact Information:
                    <br />
                    <a href="mailto:devops@haul247.co" className="faq-links">
                        Email: devops@haul247.co
                    </a>
                </p>
            </div>
        </div>
    )
}

export default PrivacyPage
